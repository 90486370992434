<template>
  <div class="container">
    <div>
      <a-modal
        v-model="choseDepotViewVisible"
        width="300px"
        class="choseDepotView"
        centered
        :closable="false"
        :maskClosable="false"
        :cancel-button-props="{ props: { display: false } }"
        @cancel="handleCancel"
      >
        <div style="text-align: center;"><span>因<span style="color: #1890ff;">{{choseDepotViewProductName}}</span>有多個倉庫，請選擇要由哪個倉庫出貨</span></div>
        <div style="text-align: center;">
          <a-select v-model="choseDepotId" style="width: 150px;">
            <a-select-option
              v-for="item in choseDepotViewData"
              :value="item.depotId"
              :key="item.depotId"
            >
              {{item.depotName}}
            </a-select-option>
          </a-select>
        </div>
        <template slot="footer">
          <a-button key="back" style="display: none;">
          </a-button>
          <a-button key="submit" type="primary" @click="choseDepotViewSubmit">
            確認
          </a-button>
        </template>
      </a-modal>
    </div>
    <div class="distribute-action">
      <a-button class="addButton2" @click="showAddOrderView"
        >新增出貨<a-icon type="plus"
      /></a-button>
      <div class="calendar">
        <a-select :defaultValue="2" style="width: 100px;" @change="changeDate">
          <a-select-option :value="0">今天</a-select-option>
          <a-select-option :value="1">本週</a-select-option>
          <a-select-option :value="2">本月</a-select-option>
          <a-select-option :value="3">上個月</a-select-option>
          <a-select-option :value="4">全部</a-select-option>
        </a-select>
        <a-range-picker v-model="differentDate" @change="onChange" />
      </div>
      <div class="search-wrapper">
        <div class="search-input" style="width: 320px;">
          <a-input-search
            v-model="search"
            placeholder="搜尋單號、客戶名稱或收件人"
            enter-button
            @search="searchHandler"
          />
        </div>
      </div>
      <a-button style="background-color: #84c31c; color: white; font-weight: bold; font-size: large;" @click="exportOrderList">匯出<a-icon type="export" /></a-button>
    </div>
    <div class="itemMenu">
      <a-table
        :columns="columns"
        :data-source="tableData"
        bordered
        rowKey="id"
        :pagination="false"
        :loading='isGettingCustomer'
      >
        <template
          v-for="col in [
            'date',
            'orderNo',
            'clientName',
            'productAmount',
            'totalPrice',
            'remark',
            'operation'
          ]"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <template v-if="col === 'orderNo'">
              <span @click="showDetail(record)" style="color: #1890ff; cursor: pointer; margin-right: 5px;" :id=record.orderNo>{{
                text
                }}</span>
              <a-button @click="copyOrderNo(text,record.orderNo)" type="link" style="padding-left: 0px;"><a-icon type="copy" size="small" /></a-button>
              <span style="display: none;">{{record.orderId}}</span>
            </template>
            <template v-else-if="col === 'remark'">
              {{ record.action === 'CANCEL_ORDER' ? '註銷' : '' }}
            </template>
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <template v-if="record.action === 'CANCEL_ORDER'">
            <a-button type="link" size="small" disabled>編輯</a-button>
            <a-button type="link" size="small" disabled>取消訂單</a-button>
          </template>
          <template v-else>
            <a-button type="link" size="small" @click="editHandler(record)"
              >編輯</a-button
            >
            <a-popconfirm
              title="取消訂單後將無法復原"
              @confirm="() => cancelHandler(record)"
            >
              <a-button type="link" size="small">取消訂單</a-button>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </div>
    <div class="pagination">
      <a-pagination
        class="pagination"
        v-model="pageNumber"
        :page-size-options="pageSizeOptions"
        :total="total"
        show-size-changer
        :page-size="pageSize"
        :show-total="total => `總共 ${total} 筆`"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}筆/頁</span>
        </template>
      </a-pagination>
    </div>

    <div class="addOrderView">
      <a-modal
        id="modal-wrapper"
        v-model="orderViewVisible"
        :title="orderModalTitle"
        width="1200px"
        @cancel="handleCancel"
        :destroyOnClose="true"
      >
        <div class="modal-body">
          <a-form-model
            layout="horizontal"
            ref="ruleForm"
            :model="ruleList"
            :rules="rules"
          >
            <div class="firstPart">
              <div class="firstPart-item">
                <a-form-model-item class="custom-form-item" label="出貨日期" prop="stockOutDate">
                  <div
                    v-if="orderModalTitle !== '訂單詳情'"
                    style="width: 315px;"
                  >
                    <a-date-picker
                      v-model="dateForOrderNo"
                      @change="salesDate"
                    />
                  </div>
                  <div v-else style="width: 315px;">
                    {{ orderDetail.salesDay }}
                  </div>
                </a-form-model-item>
                <a-form-model-item
                  class="custom-form-item"
                  label="出貨單號"
                  v-model="orderNumber"
                >
                  <div v-if="orderModalTitle !== '訂單詳情'">
                    {{ orderNumber }}
                  </div>
                  <div v-else>
                    {{ orderDetail.orderNo }}
                  </div>
                </a-form-model-item>
                <a-form-model-item class="custom-form-item" label="統一編號">
                  <div v-if="orderModalTitle !== '訂單詳情'">
                    {{ list.vatNumber }}
                  </div>
                  <div v-else>
                    {{ orderDetail.vatNumber }}
                  </div>
                </a-form-model-item>
              </div>
              <div class="firstPart-item">
                <a-form-model-item
                  class="custom-form-item"
                  label="客戶類別"
                  prop="customerClass"
                >
                    <a-select
                      show-search
                      placeholder="請選擇"
                      style="width: 175px"
                      v-model="ruleList.customerClass"
                      @change="cusListChange"
                      :style="orderModalTitle == '訂單詳情' ? 'color: rgba(0, 0, 0, 0.65);' : ''"
                      :disabled="orderModalTitle == '訂單詳情'"
                    >
                      <a-select-option
                        v-for="item in classesList"
                        :value="item.id"
                        :key="item.id"
                      >
                        {{ item.className }}
                      </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item
                  class="custom-form-item"
                  label="客戶名稱"
                  prop="optionId"
                >
                    <a-select
                      show-search
                      placeholder="請選擇"
                      style="width: 175px"
                      :filter-option="filterOption"
                      v-model="ruleList.optionId"
                      @change="handleChange"
                      :style="orderModalTitle == '訂單詳情' ? 'color: rgba(0, 0, 0, 0.65);' : ''"
                      :disabled="orderModalTitle == '訂單詳情'"
                    >
                      <a-select-option v-for="item in cusList" :value="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item
                  class="custom-form-item"
                  label="客戶電話"
                  v-model="list.tel"
                >
                  <div v-if="orderModalTitle === '新增出貨'">
                    {{ list.tel }}
                  </div>
                  <div v-else>
                    {{ orderDetail.phoneNumber }}
                  </div>
                </a-form-model-item>
              </div>
              <div class="firstPart-item">
                <a-form-model-item class="custom-form-item" label="收件人">
                  <div
                    v-if="orderModalTitle !== '訂單詳情'"
                    style="width: 315px"
                  >
                    <a-select
                      show-search
                      placeholder="請選擇"
                      style="width: 175px"
                      v-model="recipientId"
                      @change="recipientChange"
                    >
                      <a-select-option
                        v-for="item in list.receiverList"
                        :value="item.id"
                      >
                        {{ item.receiver }}
                      </a-select-option>
                    </a-select>
                    <span>
                      {{ defaultOption }}
                    </span>
                  </div>
                  <div v-else style="width: 315px">
                    <div v-if="orderDetail.receiver !== ''">
                      {{ orderDetail.receiver }}
                    </div>
                    <div v-else>
                      {{
                        list.defaultReceiveInfo === 0
                          ? list.name
                          : list.companyName
                      }}
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item class="custom-form-item" label="收件電話">
                  <div v-if="orderModalTitle !== '訂單詳情'">
                    {{ receiverList.tel }}
                  </div>
                  <div v-else>
                    {{ orderDetail.receiverPhone }}
                  </div>
                </a-form-model-item>
                <a-form-model-item class="custom-form-item" label="收件地址">
                  <div
                    style="display: flex"
                    v-if="orderModalTitle !== '訂單詳情'"
                  >
                    <div>
                      {{ receiverList.postCode }}
                    </div>
                    <div>
                      {{ receiverList.address }}
                    </div>
                  </div>
                  <div style="display: flex" v-else>
                    <div style="width: 30px;" v-if="orderDetail.receivePostCode">
                      {{ orderDetail.receivePostCode }}
                    </div>
                    <div>
                      {{ orderDetail.receiveAddress }}
                    </div>
                  </div>
                </a-form-model-item>
              </div>
            </div>
            <div class="sales-detail">
              <a-form-model
                class="option-wrapper"
                ref="ruleForm"
                :rules="rules"
              >
                <a-form-model-item
                  label="付款方式"
                  class="option-content"
                  prop="payment"
                >
                  <a-radio-group v-model="payments"
                                 v-if="orderModalTitle !== '訂單詳情'">
                    <a-radio :value="1">貨到付款</a-radio>
                    <a-radio :value="2">匯款</a-radio>
                    <a-radio :value="3">現金</a-radio>
                  </a-radio-group>
                  <div v-else>{{payments === 1 ? '貨到付款' : payments === 2 ? '匯款' : '現金'}}</div>
                </a-form-model-item>
                <a-form-model-item
                  label="出貨方式"
                  prop="shipment"
                  class="option-content"
                >
                  <a-radio-group v-model="shipment"
                                 @change="changeShipment($event.target.value)"
                                 v-if="orderModalTitle !== '訂單詳情'">
                    <a-radio :value="shipmentValueEnum.HAND_IN">{{ shipmentMsgEnum.HAND_IN }}</a-radio>
                    <a-radio :value="shipmentValueEnum.T_CAT">{{ shipmentMsgEnum.T_CAT }}宅配</a-radio>
                    <a-radio :value="shipmentValueEnum.SELF_PICK_UP">{{ shipmentMsgEnum.SELF_PICK_UP }}</a-radio>
                  </a-radio-group>
                  <div v-else>{{shipmentMsgEnum[shipmentValueToKeyEnum[shipment]]}}{{shipment === shipmentValueEnum.T_CAT ? '宅配' : ''}}</div>
                </a-form-model-item>
                <a-form-model-item
                  class="option-content"
                  label="件數"
                >
                  <a-button type="primary" @click="clickPiecesAmountButton(-1)" v-if="orderModalTitle != '訂單詳情'"><a-icon type="minus"></a-icon></a-button>
                  <a-input v-if="orderModalTitle !== '訂單詳情'" style="width: 80px; margin: 0 8px;" v-model="piecesAmount" type="number" @change="changePiecesAmount($event.target.value)"></a-input>
                  <span v-else>{{piecesAmount}}</span>
                  <a-button type="primary" @click="clickPiecesAmountButton(1)" v-if="orderModalTitle != '訂單詳情'"><a-icon type="plus"></a-icon></a-button>
                </a-form-model-item>
                <div class="t-cat-freight" v-for="(e, i) in freights" :key="e._id">
                  <div>包裹{{ i + 1 }}</div>
                  <a-form-model-item
                    class="option-content"
                    label="商品內容"
                  >
                    <template v-if="orderModalTitle !== '訂單詳情'">
                      <a-checkbox :disabled="orderModalTitle == '訂單詳情'" v-model="e.isChicken">雞肉</a-checkbox>
                      <a-checkbox :disabled="orderModalTitle == '訂單詳情'" v-model="e.isEgg">雞蛋</a-checkbox>
                      <a-checkbox :disabled="orderModalTitle == '訂單詳情'" v-model="e.isVegetable">蔬菜</a-checkbox>
                    </template>
                    <span v-else>{{e.isChicken?'雞肉':null}} {{e.isEgg?'雞蛋':null}} {{e.isVegetable?'蔬菜':null}}</span>
                  </a-form-model-item>
                  <a-form-model-item
                    label="材積單位"
                    class="option-content"
                  >
                    <a-radio-group
                      v-model="e.volume"
                      @change="changeVolume($event.target.value, i)"
                      v-if="orderModalTitle !== '訂單詳情'"
                    >
                      <a-radio :value="volumeValueEnum.SIXTY_CM">
                        {{volumeMsgEnum.SIXTY_CM}}
                      </a-radio>
                      <a-radio :value="volumeValueEnum.NINETY_CM">
                        {{volumeMsgEnum.NINETY_CM}}
                      </a-radio>
                      <a-radio :value="volumeValueEnum.HUNDRED_TWENTY_CM">
                        {{volumeMsgEnum.HUNDRED_TWENTY_CM}}
                      </a-radio>
                    </a-radio-group>
                    <div v-else>{{volumeMsgEnum[volumeValueToKeyEnum[e.volume]]}}</div>
                  </a-form-model-item>
                  <a-form-model-item
                    label="溫層類別"
                    class="option-content"
                  >
                    <a-radio-group
                      v-model="e.temperatureCategory"
                      @change="changeTemperature($event.target.value, shipmentValueEnum.T_CAT, i)"
                      v-if="orderModalTitle !== '訂單詳情'"
                    >
                      <a-radio :value="temperatureValueEnum.ROOM_TEMPERATURE">
                        {{ temperatureMsgEnum.ROOM_TEMPERATURE }}
                      </a-radio>
                      <a-radio :value="temperatureValueEnum.LOW_TEMPERATURE">
                        {{ temperatureMsgEnum.LOW_TEMPERATURE }}
                      </a-radio>
                    </a-radio-group>
                    <div v-else>{{temperatureMsgEnum[temperatureValueToKeyEnum[e.temperatureCategory]]}}</div>
                  </a-form-model-item>
                  <a-form-model-item
                    class="option-content"
                    label="物流編號"
                    v-if="shipment === shipmentValueEnum.T_CAT"
                  >
                    <a-input
                      v-model="e.trackingNo"
                      placeholder="請輸入物流編號"
                      v-if="orderModalTitle !== '訂單詳情'"
                    />
                    <span v-else>{{e.trackingNo}}</span>
                  </a-form-model-item>
                  <a-form-model-item
                    class="option-content"
                    label="運費金額"
                  >
                    <span style="font-weight: bold">$</span>
                    <a-input
                      v-model="e.shippingFee"
                      style="width: 180px"
                      :min="0"
                      placeholder="請輸入運費金額(空為0)"
                      v-if="orderModalTitle !== '訂單詳情'"
                      type="number"
                      @change="handleInputShippingFee($event, i)"
                    />
                    <span v-else>{{e.shippingFee}}</span>
                  </a-form-model-item>
                </div>
              </a-form-model>
            </div>
            <div class="second-part">
              <div class="second-part-item">
                <div>
                  <div style="display: flex">
                    <h3 style="margin-top: 10px">出貨商品</h3>
                    <div v-if="orderModalTitle !== '訂單詳情'">
                      <a-button
                        type="primary"
                        size="small"
                        class="editable-add-btn"
                        @click="handleAdd"
                      >
                        <a-icon type="plus" />
                      </a-button>
                    </div>
                    <div v-else></div>
                  </div>
                  <a-table
                    class="saleTable"
                    bordered
                    rowKey="barcode"
                    :columns="orderColumns"
                    :data-source="orderData"
                    :pagination="orderModalTitle !== '訂單詳情' ? true : false"
                    @change="distributeChange"
                  >
                  </a-table>
                  <a-form-model-item class="remark-wrapper" label="備註">
                    <a-row type="flex" justify="space-between">
                      <a-col :span="20">
                        <div v-if="orderModalTitle !== '訂單詳情'">
                          <a-textarea
                            v-model="remark"
                            line-height="center"
                            placeholder="請輸入"
                            :auto-size="{ minRows: 7, maxRows: 7 }"
                            @pressEnter="onPressEnterStockRemark"
                          />
                        </div>
                        <div v-else>
                          <a-textarea
                                  :value="orderDetail.remark"
                                  line-height="center"
                                  disabled
                                  :auto-size="{ minRows: 7, maxRows: 7 }"
                          />
                        </div>
                      </a-col>
                      <a-col :span="3">
                        <span>
                          合計: ${{
                            totalOrder.amount.toString()
                              .replace(/^(\d+\.+\d{1,2})\d*$/, '$1')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                        </span>
                        <br/>
                        <span>
                          折讓: $ <a-input v-model="allowance" style="width: 50px;" v-if="orderModalTitle !== '訂單詳情'"></a-input><span v-else>{{(Number(orderDetail.allowance) || 0).toString()
                              .replace(/^(\d+\.+\d{1,2})\d*$/, '$1')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</span>
                        </span>
                        <br/>
                        <span>
                          運費: ${{
                            countShippingFee
                          }}
                        </span>
                        <br/>
                        <span>
                          總計: ${{
                            totalAmount
                              .toString()
                              .replace(/^(\d+\.+\d{1,2})\d*$/, '$1')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }}
                        </span>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                </div>
              </div>
            </div>
          </a-form-model>
          <div class="print-wrapper">
            <div style="margin-left: 21px;"><h3>列印操作</h3></div>
            <div class="button-wrapper">
              <ModalExample
                ref="IdentifyBtn"
                :distirbuteHandler="handleOk"
                :orderData="orderData"
                :orderDetail="orderDetail"
                :orderId="orderId"
                :orderTitle="orderModalTitle"
                :list="list"
                :Calculate="{ count: totalOrder.count, totalPrice: totalOrder.amount }"
                :parentHandleCancel="handleCancel"
                @passTemplateType="getTemplateType"
              />
            </div>
          </div>
        </div>
        <template slot="footer">
          <div v-if="orderModalTitle !== '訂單詳情'">
            <a-button key="back" @click="handleCancel">
              取消
            </a-button>
            <a-button key="submit" type="primary" @click="handleOk">
              儲存
            </a-button>
          </div>
          <div v-else></div>
        </template>
      </a-modal>
    </div>
  </div>
</template>
<script>
import formatPrice from '@/components/thousand'
import ModalExample from './Execel/index'
import moment from 'moment'
import { computedWeight } from '@/unit/dictionary/computed'
import Fragment from '@/components/Fragment'
import Decimal from 'decimal.js'

import {
  createDefaultFreightState,
  shipmentEnum,
  shipmentMsgEnum,
  shipmentValueEnum,
  shipmentValueToKeyEnum,
  temperatureEnum,
  temperatureMsgEnum,
  temperatureValueEnum,
  temperatureValueToKeyEnum,
  translateCustomerFreightState,
  volumeEnum,
  volumeMsgEnum,
  volumeValueEnum,
  volumeValueToKeyEnum
} from '../../utils/freight-helper'

let freightId = 0
const checkWeightUnit = (unit) => ['公斤','公克','台斤'].includes(unit)
const newOrderData = {
  order: 0,
  barCode: '',
  productName: '',
  depotName: '',
  unit: '',
  id: undefined,
  productId: undefined,
  clientPrice: 0,
  price: 0,
  amount: 1,
  discount: 0,
  remark: '',
  orderPrice: 0,
  weight: 0,
  isEditAmount: true,
  isEditRemark: true,
  isEditDiscount: true,
  isEditClientPrice: true,
  productCategories: '',
  depotId: undefined,
}
const getDefaultTCatFreight = ()  => {
  return {
    _id: ++freightId,
    isChicken: false,
    isEgg: false,
    isVegetable: false,
    volume: volumeValueEnum.SIXTY_CM,
    temperatureCategory: temperatureValueEnum.LOW_TEMPERATURE,
    trackingNo: undefined,
    shippingFee: undefined,
  }
}

export default {
  components: { ModalExample },
  data() {
    let differentDate = [
      moment()
        .date(1)
        .startOf('day'),
      moment().endOf('month')
    ]
    return {
      isGettingCustomer:false,
      orderViewVisible: false,
      choseDepotViewVisible: false,
      choseDepotViewProductName: '',
      choseDepotViewData: [],
      choseDepotLastRow:{},
      choseDepotId: '',
      orderModalTitle: '',
      customerList: [],
      remark: '',
      isChicken: false,
      isEgg: false,
      isVegetable: false,
      piecesAmount: 1,
      orderColumns: [
        {
          title: '商品條碼',
          dataIndex: 'barCode',
          align: 'center',
          width: '12%',
          customRender: (value, row, index) => {
            return {
              children:
                this.orderModalTitle !== '訂單詳情' ? (
                  <div>
                    <a-input
                      autoFocus
                      style={{ width: '130px' }}
                      onChange={barCode =>
                        this.pushName(
                          barCode,
                          row,
                          (index =
                            (this.currentPage - 1) * this.pageSizes + index)
                        )
                      }
                      vModel={row.barCode}
                      placeholder="請手動輸入商品條碼"
                    ></a-input>
                  </div>
                ) : (
                  <div>{row.barCode}</div>
                )
            }
          },
          scopedSlots: { customRender: 'barCode' }
        },
        {
          title: '商品名稱',
          dataIndex: 'productName',
          align: 'center',
          customRender: (value, row) => {
            return {
              children: <div>{row.productName}</div>
            }
          }
        },
        {
          title: '倉庫',
          dataIndex: 'depotName',
          align: 'center',
        },
        {
          title: '數量',
          dataIndex: 'amount',
          align: 'center',
          width: '8%',
          customRender: (val, row) => {
            return checkWeightUnit(row.unit)
              ? parseFloat(row.weight).toFixed(3)
              : this.Quantity(val, row, 'amount')
          },
          scopedSlots: { customRender: 'amount' }
        },
        {
          title: '單位',
          dataIndex: 'unit',
          align: 'center',
          width: '8%',
          scopedSlots: { customRender: 'unit' }
        },
        {
          title: () => (
            <div>
              建議售價
            </div>
          ),
          dataIndex: 'price',
          align: 'center',
          width: '9.5%',
          customRender: (val, row) => {
            return {
              children: <div>${formatPrice( parseInt(row.price))}</div>
            }
          }
        },
        {
          title: () => (
            <div>
              單價
            </div>
          ),
          dataIndex: 'clientPrice',
          align: 'center',
          width: '120px',
          customRender: (val, row) => {
            return this.Quantity(val, row, 'clientPrice')
            // return <div>${formatPrice(parseInt(row.clientPrice))}</div>
          }
        },
        {
          title: '折扣',
          dataIndex: 'discount',
          align: 'center',
          width: '8%',
          customRender: (val, row) => {
            return this.Quantity(val, row, 'discount')
          },
          scopedSlots: { customRender: 'discount' }
        },
        {
          title: '小計',
          dataIndex: 'orderPrice',
          align: 'center',
          width: '120px',
          customRender: (_, row) => {
            return {
              children: this.$set(
                row,
                'orderPrice',
                formatPrice(
                  `$${(new Decimal(row.clientPrice).times(new Decimal(checkWeightUnit(row.unit) ? row.weight : row.amount)).toDecimalPlaces(2) - row.discount)}`
                )
              )
            }
          }
        },
        {
          title: '備註',
          dataIndex: 'remark',
          align: 'center',
          width: '150px',
          customRender: (val, row) => {
            return this.Quantity(val, row, 'remark')
          },
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '6%',
          align: 'center',
          customRender: (value, row, index) => ({
            children: (
              <div>
                {this.orderModalTitle !== '訂單詳情' ? (
                  <div>
                    <a-popconfirm
                      title="確定要刪除嗎?"
                      onConfirm={() =>
                        this.deleteOrder(
                          row,
                          (index =
                            (this.currentPage - 1) * this.pageSizes + index)
                        )
                      }
                    >
                      <a>刪除</a>
                    </a-popconfirm>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            )
          })
        }
      ],
      orderId: '',
      orderData: [],
      list: {},
      cusList: [],
      inventoryList: [],
      searchBarcode: '',
      filterBarcode: {},
      currentPage: 1,
      pageSizes: 10,
      totalPages: 10,
      selectList: [],
      classesList: [],
      differentDate,
      tableData: [],
      orderList: [],
      detailInfo: {},
      columns: [
        {
          title: '日期',
          dataIndex: 'date',
          align: 'center',
          scopedSlots: { customRender: 'date' }
        },
        {
          title: '單號',
          dataIndex: 'orderNo',
          align: 'center',
          scopedSlots: { customRender: 'orderNo' }
        },
        {
          title: '客戶名稱',
          dataIndex: 'clientName',
          align: 'center',
          scopedSlots: { customRender: 'clientName' }
        },
        {
          title: '收件人',
          dataIndex: 'recipientName',
          align: 'center',
        },
        {
          title: '總金額',
          dataIndex: 'totalPrice',
          align: 'center',
          customRender: (val, row) => {
            return {
              children: '$' + formatPrice(row.totalPrice.toFixed(2))
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
        {
          title: '狀態',
          dataIndex: 'remark',
          align: 'center',
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '操作者',
          dataIndex: 'operator',
          align: 'center',
        },
      ],
      search: '',
      pageSizeOptions: ['10', '30', '50', '100'],
      pageNumber: 1,
      pageSize: 10,
      total: 30,
      startDate: differentDate[0].format('YYYY-MM-DD'),
      endDate: differentDate[1].format('YYYY-MM-DD'),
      dateForOrderNo: '',
      orderNumber: '',
      payments: 1,
      shipment: shipmentValueEnum.HAND_IN,
      temperatureCategory: temperatureValueEnum.LOW_TEMPERATURE,
      volume: volumeValueEnum.SIXTY_CM,
      trackingNo: '',
      shippingFee: 0,
      receiverList: {},
      orderDetail: {},
      recipientId: '',
      defaultList: [],
      defaultReceiver: '',
      defaultOption: '',
      findDefaultInfo: [],
      templateType: '',
      printed: false,
      ruleList: {
        stockOutDate: '',
        customerClass: '',
        optionId: ''
      },
      rules: {
        stockOutDate: [{ required: true, message: '請選擇', trigger: 'blur' }],
        customerClass: [{ required: true, message: '請選擇', trigger: 'blur' }],
        optionId: [{ required: true, message: '請選擇', trigger: 'blur' }],
        payment: [{ required: true, message: '請選擇', trigger: 'blur' }],
        shipment: [{ required: true, message: '請選擇', trigger: 'blur' }],
      },
      allowance: 0,
      lasted: false,
      isShiftEnterStockRemark: false,
      clientFreightsObj: createDefaultFreightState(),
      freights: [getDefaultTCatFreight()],
    }
  },
  created() {
    this.distributeList()
    this.getClass()
    this.getCustomerList()
  },
  mounted() {
    this.CommodityDetail(this.searchBarcode)
  },
  methods: {
    updateFreights() {
      const {  piecesAmount } = this

      const piecesAmountNumber = Number(piecesAmount)

      if (typeof piecesAmountNumber === 'number') {
        const newFreights = this.freights.slice(0, piecesAmountNumber)
        for (let i = 0; i < piecesAmountNumber; i++) {
          if (newFreights[i] == null)
            newFreights.push(getDefaultTCatFreight())
        }
          this.freights = newFreights
        this.updateShippingFeeByCustomerIdChange()
        return
      }
      this.freights = [getDefaultTCatFreight()]
      this.updateShippingFeeByCustomerIdChange()
    },
    updateShippingFeeByCustomerIdChange() {
      this.freights?.forEach((e, i) => {
        this.changeVolume(e.volume, i)
      })
    },
    changeTemperature(temperature, shipmentValue, editIndex) {
      this.freights[editIndex].volume = volumeValueEnum.SIXTY_CM
      const shipment = shipmentValueToKeyEnum[this.shipment]
      if (temperature === temperatureValueEnum.ROOM_TEMPERATURE ||
        temperature === temperatureValueEnum.LOW_TEMPERATURE)
        this.freights[editIndex].shippingFee = shipment === shipmentEnum.SELF_PICK_UP ? 0 : this.clientFreightsObj[shipment][temperatureValueToKeyEnum[temperature]][volumeValueToKeyEnum[this.freights[editIndex].volume]]
    },
    changeVolume(volume, editIndex) {
      const shipment = shipmentValueToKeyEnum[this.shipment]
      this.freights[editIndex].shippingFee = shipment === shipmentEnum.SELF_PICK_UP ? 0 : this.clientFreightsObj[shipment][temperatureValueToKeyEnum[this.freights[editIndex].temperatureCategory]][volumeValueToKeyEnum[volume]]
    },
    changePiecesAmount(value) {
      const numberValue = Number(value)
      if (numberValue < 0 || typeof numberValue !== 'number') this.piecesAmount = 0
      this.updateFreights()
    },
    clickPiecesAmountButton(plusValue) {
      const { piecesAmount } = this
      const piecesAmountNumber = Number(piecesAmount)
      let nextPiecesAmount = piecesAmountNumber + plusValue

      if (nextPiecesAmount < 0 || typeof nextPiecesAmount !== 'number') nextPiecesAmount = 0

      this.piecesAmount = nextPiecesAmount

      if (piecesAmountNumber !== nextPiecesAmount) this.updateFreights()
    },
    handleInputShippingFee(ev, i) {
      const value = ev.target.value
      const changeValue = (v) => i != null ? this.freights[i].shippingFee = v : this.shippingFee = v

      if (/^-/.test(value)) changeValue(0)
      else if (/^0+\d+/.test(value)) changeValue(value?.replace(/^0+(\d+)/g, '$1') || 0)
    },
    getTemplateType(e) {
      this.templateType = e
    },
    showAddOrderView() {
      this.orderModalTitle = '新增出貨'
      this.orderViewVisible = true
      this.payments = 1
      this.shipment = shipmentValueEnum.HAND_IN
      this.temperatureCategory = temperatureValueEnum.LOW_TEMPERATURE
      this.volume = volumeValueEnum.SIXTY_CM
      this.isChicken = false
      this.isEgg = false
      this.isVegetable = false
      this.piecesAmount = 1
      this.allowance = 0
      if (this.orderColumns.every(item => item.title !== '操作')) {
        this.orderColumns.push({
          title: '操作',
          dataIndex: 'operation',
          width: '6%',
          align: 'center',
          customRender: (value, row, index) => ({
            children: (
              <div>
                {this.orderModalTitle !== '訂單詳情' ? (
                  <div>
                    <a-popconfirm
                      title="確定要刪除嗎?"
                      onConfirm={() =>
                        this.deleteOrder(
                          row,
                          (index =
                            (this.currentPage - 1) * this.pageSizes + index)
                        )
                      }
                    >
                      <a>刪除</a>
                    </a-popconfirm>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            )
          })
        })
      }
    },
    async editHandler(record) {
      this.currentPage = 1
      this.orderViewVisible = true
      this.orderModalTitle = '編輯出貨'
      this.orderId = record.orderId
      if (this.orderColumns.every(item => item.title !== '操作')) {
        this.orderColumns.push({
          title: '操作',
          dataIndex: 'operation',
          width: '6%',
          align: 'center',
          customRender: (value, row, index) => ({
            children: (
              <div>
                {this.orderModalTitle !== '訂單詳情' ? (
                  <div>
                    <a-popconfirm
                      title="確定要刪除嗎?"
                      onConfirm={() =>
                        this.deleteOrder(
                          row,
                          (index =
                            (this.currentPage - 1) * this.pageSizes + index)
                        )
                      }
                    >
                      <a>刪除</a>
                    </a-popconfirm>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            )
          })
        })
      }

      this.lasted = true
      await this.$api.Distribute.getDistributeDetail({
        orderId: record.orderId
      }).then(async res => {
        this.orderDetail = res.data
        this.allowance = this.orderDetail.allowance
        this.payments = res.data.payment
        this.shipment = res.data.shipment
        this.ruleList.customerClass = res.data.classId
        this.ruleList.optionId = res.data.clientId
        this.cusList = this.customerList.filter(item => {
          return item.classes.id === res.data.classId
        })
        this.list = this.customerList.find(item => {
          return item.id === this.ruleList.optionId
        })
        this.getClientFreights(res.data.clientId, false)
        this.passShipDataByApiResponseData(res.data)
        this.trackingNo = res.data.trackingNo
        this.remark = res.data.remark
        this.defaultReceiver = res.data.defaultReceiveInfo
        this.piecesAmount = res.data.piecesAmount > 0 ? res.data.piecesAmount : 0
        let barcodes = []
        res.data.orderDetailItemResponseList.map(item=>{
          barcodes.push(item.barcode)
        })
        await this.$api.Commodity.getProductSalesByCode({
          clientId: res.data.clientId,
          barcodes: barcodes
        }).then(res => {
          this.selectList = [].concat.apply([], res.data)
        })
        this.list = this.customerList.find(item => {
          return item.id === this.ruleList.optionId
        })
        this.findDefaultInfo = [
          { id: '', receiver: '', address: '', tel: '', postCode: '' },
          { id: '', receiver: '', address: '', tel: '', postCode: '' }
        ]
        this.findDefaultInfo.push(...this.list.recipientList)
        this.findDefaultInfo[0].id = '0'
        this.findDefaultInfo[0].receiver = this.list.name
        this.findDefaultInfo[0].address = this.list.address
        this.findDefaultInfo[0].postCode = this.list.postCode
        this.findDefaultInfo[0].tel = this.list.tel
        this.findDefaultInfo[1].id = '1'
        this.findDefaultInfo[1].receiver = this.list.companyName
        this.findDefaultInfo[1].address = this.list.companyAddress
        this.findDefaultInfo[1].postCode = this.list.companyPostCode
        this.findDefaultInfo[1].tel = this.list.companyTel
        this.list.receiverList = this.findDefaultInfo

        let nonDefaultList = this.list.receiverList.filter((item, i) => {
          return item.id === this.findDefaultInfo[i].id
        })
        this.defaultList = this.findDefaultInfo.filter(
          (item, i) => i === this.list.defaultReceiveInfo
        )
        if (
          nonDefaultList[this.list.defaultReceiveInfo].id ===
          this.defaultList[0].id
        ) {
          if (this.list.defaultReceiveInfo === 0) {
            this.defaultList[0].default = '同客戶資料(預設地址)'
            this.defaultOption = this.defaultList[0].default
          } else if (this.list.defaultReceiveInfo === 1) {
            this.defaultList[0].default = '同公司資料(預設地址)'
            this.defaultOption = this.defaultList[0].default
          } else {
            if (res.data.receiver === this.defaultList[0].receiver) {
              this.defaultList[0].default = '(預設地址)'
              this.defaultOption = this.defaultList[0].default
            }
          }
          nonDefaultList[this.defaultReceiver]
          this.receiverList = nonDefaultList[this.defaultReceiver]
        }
        this.recipientId = res.data.recipientId

        this.orderData = res.data.orderDetailItemResponseList.map(item => {

          let amount = item.amount
          let price
          let selectData = this.selectList.find(x => x.barcode == item.barcode && x.depotId == item.depotId)
          if(selectData){
            price = selectData.price
          }else {
            price = item.price
          }

          // this.selectList[index].amount += item.amount
          // let price = this.selectList.find(x=>x.barcode == item.barcode).price
          return {
            id: item.id,
            productId: item.productId,
            alias: item.alias,
            amount: amount,
            barCode: item.barcode,
            price: price,
            productName:item.productName,
            depotName: item.depotName,
            remark: item.remark,
            clientPrice: item.clientPrice,
            unit: item.unit,
            discount: item.discount,
            weight: item.weight,
            depotId: item.depotId,
            isEditAmount: true,
            isEditRemark: true,
            isEditDiscount: true,
            isEditClientPrice: true,
            productCategories: item.productCategories
          }
        })
      })
      this.orderNumber = record.orderNo
      this.dateForOrderNo = record.date
      this.ruleList.stockOutDate = this.dateForOrderNo
    },
    handleCancel() {
      this.purchaseViewVisible = false
      this.orderViewVisible = false
      this.orderData = []
      this.list = {}
      this.remark = ''
      this.selectList = []
      this.freights = [getDefaultTCatFreight()]
      this.ruleList.optionId = ''
      this.ruleList.customerClass = ''
      this.dateForOrderNo = ''
      this.orderNumber = ''
      this.payments = ''
      this.shipment = ''
      this.temperatureCategory = ''
      this.volume = ''
      this.trackingNo = ''
      this.shippingFee = ''
      this.recipientId = ''
      this.receiverList = {}
      this.defaultOption = ''
      this.list.recipientList = []
      this.resetForm()
    },
    changeShipment() {
      this.updateFreights()
    },
    addNewItem(row, editKey) {
      row[editKey] = false
      if (editKey === 'isEditAmount') {
        document.getElementsByClassName('bbb')[1].focus()
      } else if (editKey === 'isEditDiscount') {
        document.getElementsByClassName('bbb')[1].focus()
      } else if (editKey === 'isEditClientPrice') {
        document.getElementsByClassName('bbb')[1].focus()
      }
    },
    inputORnot(row, editKey) {
      row[editKey] = true
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    cusListChange(id) {
      this.cusList = this.customerList.filter(item => {
        return item.classes.id === id
      })
      this.ruleList.optionId = ''
      this.list = {}
      this.recipientId = ''
      this.receiverList = {}
      this.$refs.ruleForm.validate()
    },
    handleChange(id) {
      this.$refs.ruleForm.validate()
      this.list = this.customerList.find(item => {
        return item.id === id
      })

      //把資料叫回來組一個陣列拿index去判斷defaultReceiveInfo
      this.findDefaultInfo = [
        { id: '', receiver: '', address: '', tel: '', postCode: '' },
        { id: '', receiver: '', address: '', tel: '', postCode: '' }
      ]
      this.findDefaultInfo.push(...this.list.recipientList)
      this.findDefaultInfo[0].id = '0'
      this.findDefaultInfo[0].receiver = this.list.name
      this.findDefaultInfo[0].address = this.list.address
      this.findDefaultInfo[0].postCode = this.list.postCode
      this.findDefaultInfo[0].tel = this.list.tel
      this.findDefaultInfo[1].id = '1'
      this.findDefaultInfo[1].receiver = this.list.companyName
      this.findDefaultInfo[1].address = this.list.companyAddress
      this.findDefaultInfo[1].postCode = this.list.companyPostCode
      this.findDefaultInfo[1].tel = this.list.companyTel

      this.list.receiverList = this.findDefaultInfo
      this.defaultList = this.findDefaultInfo.filter(
        (item, i) => i === this.list.defaultReceiveInfo
      )

      if (this.list.defaultReceiveInfo === 0) {
        this.defaultList[0].default = '同客戶資料(預設地址)'
      } else if (this.list.defaultReceiveInfo === 1) {
        this.defaultList[0].default = '同公司資料(預設地址)'
      } else {
        this.defaultList[0].default = '(預設地址)'
      }
      this.defaultOption = this.defaultList[0].default
      this.recipientId = this.defaultList[0].id
      this.receiverList = { ...this.defaultList[0] }

      this.defaultReceiver = this.list.receiverList.findIndex(
        item => item.id === this.defaultList[0].id
      )
      // this.receiverList = Object.assign({}, this.list.recipientList.filter(item=>item.id === id))[0]
      // this.recipientId = this.receiverList.id
      // this.list = JSON.parse(JSON.stringify(this.customerList.find(item => {
      //   return item.id === id
      // })))
      this.specificId = id
      // this.$api.Commodity.getSalesProduct({
      //   searchKey: '',
      //   barcode: '',
      //   clientId: this.specificId
      // }).then(res => {
      //   this.selectList = [].concat.apply([], res.data)
      //
      //   this.orderData.map((item, idx) => {
      //     if (item.barCode === this.selectList[idx].barcode) {
      //       return (item.clientPrice = this.selectList[idx].clientPrice)
      //     }
      //   })
      // })
      let barcodes = []
      this.orderData.map(item => {
        barcodes.push(item.barCode)
      })
      this.$api.Commodity.getProductSalesByCode({
        clientId: this.specificId,
        barcodes: barcodes
      }).then(res => {
        this.selectList = [].concat.apply([], res.data)
        this.orderData.map((item) => {
          let data
          data = this.selectList.find(x=>x.barcode == item.barCode)
          if(checkWeightUnit(data.unit)){
            return (item.clientPrice = data.clientPrice > 0 ? data.clientPrice : data.dealPrice)
          }
          return (item.clientPrice = data.clientPrice > 0 ? data.clientPrice : data.price)
        })
      })
      this.getClientFreights(id)
    },
    getClientFreights(customerId, isUpdate = true) {
      this.$api.Customer.getClientFreights(customerId).then(res => {
        this.clientFreightsObj = translateCustomerFreightState(res.data || [])
        if (isUpdate) this.updateShippingFeeByCustomerIdChange()
      })
    },
    recipientChange(id) {
      this.recipientId = id
      this.receiverList = Object.assign(
        {},
        this.list.receiverList.filter(item => item.id === id)
      )[0]
      if (
        this.receiverList.default === '同客戶資料(預設地址)' &&
        this.list.defaultReceiveInfo === 0
      ) {
        this.defaultOption = '同客戶資料(預設地址)'
      } else if (
        this.receiverList.default === '同公司資料(預設地址)' &&
        this.list.defaultReceiveInfo === 1
      ) {
        this.defaultOption = '同公司資料(預設地址)'
      } else if (
        this.receiverList.default === '(預設地址)' &&
        this.list.defaultReceiveInfo > 1
      ) {
        this.defaultOption = '(預設地址)'
      } else {
        this.defaultOption = ''
      }

      this.defaultReceiver = this.list.receiverList.findIndex(
        item => item.id === id
      )
    },
    salesDate(date, dateString) {
      this.dateForOrderNo = dateString

      if (dateString === '') {
        this.orderNumber = ''
      } else {
        this.ruleList.stockOutDate = this.dateForOrderNo
        this.$refs.ruleForm.validate()
        this.$api.Distribute.getOrderNo({
          date: this.dateForOrderNo
        }).then(res => {
          this.orderNumber = res.data
          this.printed = true
        })
      }
    },
    handleAdd() {
      const { orderData } = this
      this.orderData = [...orderData, { ...newOrderData }]
    },
    async pushName(barCode, row, index) {
      if (row.barCode !== '') {
        let result
        await this.$api.Commodity.getProductSalesByCode({
          clientId: this.ruleList.optionId,
          barcodes:[row.barCode]
        }).then(res => {
          result = res.data
          this.selectList = [].concat.apply([], res.data)
        })
        //輸入條碼會拿到符合的值
        if (result.length > 0) {
          let hasBarcode = false
          this.orderData.map((item, idx) => {
            //判斷現在在哪一個欄位輸入
            //如果是當前欄位就跳出
            if (idx !== index) {
              //非當前欄位且取回來的條碼已經存在
              if (result.length > 1 ? false : item.barCode === result[0].barcode) {
                //取得條碼狀態改為true
                hasBarcode = true
              }
            }
          })
          const isWeightUnit = checkWeightUnit(result[0].unit)
          //如果沒有拿過條碼就來這塞值
          if (!hasBarcode) {
            if(!isWeightUnit && result.length > 1) {
              this.choseDepotViewVisible = true
              this.choseDepotViewProductName = result[0].productName
              this.choseDepotViewData = result
              this.choseDepotLastRow = row
              this.choseDepotId = result[0].depotId
              return
            }
            row.productId = result[0].productId
            row.unit = computedWeight(undefined, result[0].unit)
            row.clientPrice = result[0].clientPrice > 0
              ? result[0].clientPrice
              : (isWeightUnit
                ? result[0].dealPrice
                : result[0].price)
            row.productName = result[0].productName
            row.price = result[0].price
            row.weight = result[0].weight
            row.productCategories = result[0].productCategories
            row.depotId = result[0].depotId
            row.depotName = result[0].depotName
          } else if (isWeightUnit) {
            let idx = this.orderData.findIndex(
              item => item.barCode === result[0].barcode
            )
            this.orderData[idx].amount += 1
            this.orderData[idx].weight += result[0].weight
            this.$message.success(
              `已合併${
                    this.orderData[idx].productName
              }`
            )
            for (let k in row) row[k] = newOrderData[k]
          }
        }
      } else {
        for (let k in row) row[k] = newOrderData[k]
      }
    },
    choseDepotViewSubmit() {
      let item
      item = this.choseDepotViewData.find(x=>x.depotId === this.choseDepotId)

      if(this.selectList.findIndex(x=>x.barcode == item.barcode) === -1) {
        this.selectList.push(item)
      }

      const idx = this.orderData?.findIndex(e => e.barcode === item.barcode && e.depotId === item.depotId)

      if (idx !== -1) {
        this.orderData[idx].amount += 1
        this.orderData[idx].weight += item.weight
        for (let k in this.choseDepotLastRow) this.choseDepotLastRow[k] = newOrderData[k]
        this.$message.success(
          `已合併${
            this.orderData[idx].productName
          } - ${this.orderData[idx].depotName}`
        )
        this.choseDepotViewVisible = false
        return
      }

      this.choseDepotLastRow.productId = item.productId
      this.choseDepotLastRow.unit = computedWeight(undefined, item.unit)
      this.choseDepotLastRow.clientPrice = item.clientPrice > 0 ? item.clientPrice : item.dealPrice
      this.choseDepotLastRow.depotName = item.depotName
      this.choseDepotLastRow.productName = item.productName
      this.choseDepotLastRow.price = item.price
      this.choseDepotLastRow.weight = item.weight
      this.choseDepotLastRow.productCategories = item.productCategories
      this.choseDepotLastRow.depotId = item.depotId
      this.choseDepotViewVisible = false
    },
    handleOk(e) {
      if (this.isShiftEnterStockRemark) return this.isShiftEnterStockRemark = false
      if(this.orderModalTitle === '訂單詳情') return
      return new Promise((resolve, reject) => {
        let productId = this.orderData.map(item => item.productId)
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (
              this.templateType ||
              e === '貼箱標籤' ||
              e.key === 'Enter' ||
              e.target.innerText === '儲 存'
            ) {
              const { freights, shipment } = this

              if (shipment === shipmentValueEnum.T_CAT) {
                if (freights.some(e => typeof e.trackingNo !== 'string' || e.trackingNo.trim().length === 0)) {
                  this.$message.error('包裹資料裡的物流編號為必填')
                  return
                }
              }

              const tracks = freights.map(e => ({
                chicken: e.isChicken,
                egg: e.isEgg,
                vegetable: e.isVegetable,
                shippingFee: e.shippingFee || 0,
                temperature: temperatureValueToKeyEnum[e.temperatureCategory],
                volume: volumeValueToKeyEnum[e.volume],
                trackingNo: shipmentValueEnum.T_CAT ? e.trackingNo : null,
              }))
              if (this.orderModalTitle === '新增出貨') {
                if (this.list.id) {
                  if (this.orderData.length !== 0) {
                    if (productId[0] !== undefined) {
                      this.$api.Distribute.addOrder({
                        recipientId: this.recipientId,
                        clientId: this.list.id,
                        remark: this.remark,
                        payment: this.payments,
                        shipment: this.shipment,
                        orderNo: this.orderNumber,
                        stockOutDate: this.dateForOrderNo,
                        defaultReceiveInfo: this.defaultReceiver,
                        allowance: this.allowance,
                        totalPrice: this.totalAmount,
                        tracks,
                        piecesAmount: this.piecesAmount,
                        orderItemRequestList: this.orderData.map(item => {
                          return {
                            barcode: item.barCode,
                            amount: item.amount,
                            weight: item.weight,
                            discount: parseInt(item.discount),
                            clientPrice: item.clientPrice,
                            price:
                              item.clientPrice > 0
                                ? item.clientPrice * item.amount - item.discount
                                : item.price * item.amount - item.discount,
                            remark: item.remark,
                            depotId: item.depotId
                          }
                        })
                      })
                        .then(res => {
                          this.$message.success('出貨確認成功')
                          if (this.templateType || e === '貼箱標籤') {
                            this.orderId = res.data.orderId
                            this.$api.Distribute.getDistributeDetail({
                              orderId: res.data.orderId
                            }).then(response => {
                              this.orderData =
                                response.data.orderDetailItemResponseList
                              this.orderDetail = response.data
                              this.printed = true
                              this.templateType = ''
                              resolve()
                            })
                            setTimeout(() => {
                              this.orderViewVisible = false
                            }, 3000)
                            setTimeout(() => {
                              this.handleCancel()
                            }, 3500)
                          } else {
                            this.orderViewVisible = false
                            this.handleCancel()
                            this.templateType = ''
                          }
                          this.resetPage()
                        })
                        .catch(err => {
                          console.log(err.response.data.message)
                          const stock = this.orderData.reduce((p, c) => {
                            p[c.productId] = parseInt(c.amount)
                            return p
                          }, {})
                          const quantity = this.selectList.some(item => {
                            return item.amount < stock[item.productId]
                          })
                          if (quantity) {
                            this.$message.error(
                              `${err.response.data.message}`,
                              3
                            )
                          }
                        })
                    } else {
                      this.$message.error('請選擇商品')
                    }
                  } else {
                    this.$message.error('請先新增商品')
                  }
                } else {
                  this.$message.error('請選擇客戶')
                }
              } else {
                this.$api.Distribute.editOrder({
                  clientId: this.ruleList.optionId,
                  orderId: this.orderId,
                  recipientId: this.recipientId,
                  remark: this.remark,
                  payment: this.payments,
                  shipment: this.shipment,
                  temperatureCategory: this.temperatureCategory,
                  volume: this.volume,
                  orderNo: this.orderNumber,
                  stockOutDate: this.dateForOrderNo,
                  trackingNo: this.trackingNo,
                  shippingFee: this.shipment === 3 ? 0 : this.shippingFee,
                  defaultReceiveInfo: this.defaultReceiver,
                  allowance: this.allowance,
                  totalPrice: this.totalAmount,
                  tracks,
                  chicken: this.isChicken,
                  egg: this.isEgg,
                  vegetable: this.isVegetable,
                  piecesAmount: this.piecesAmount,
                  orderItemRequestList: this.orderData.map(item => {
                    return {
                      id: item.id,
                      barcode: item.barCode,
                      amount: item.amount,
                      weight: item.weight,
                      discount: parseInt(item.discount),
                      clientPrice: item.clientPrice,
                      price:
                        item.clientPrice > 0
                          ? item.clientPrice * item.amount - item.discount
                          : item.price * item.amount - item.discount,
                      remark: item.remark,
                      depotId: item.depotId
                    }
                  })
                })
                  .then(() => {
                    this.$message.success('編輯出貨成功')
                    if (this.templateType || e === '貼箱標籤') {
                      this.$api.Distribute.getDistributeDetail({
                        orderId: this.orderId
                      }).then(response => {
                        this.orderDetail = response.data
                        this.orderData =
                          response.data.orderDetailItemResponseList
                        resolve()
                      })
                      this.templateType = ''
                      // setTimeout(() => {
                      //   console.log(3)
                      //   this.orderViewVisible = false
                      // }, 4000)
                      setTimeout(() => {
                        console.log('關彈窗')
                        this.handleCancel()
                      }, 3500)
                    } else {
                      this.orderViewVisible = false
                      this.handleCancel()
                      this.templateType = ''
                    }
                    this.templateType = ''
                    this.resetPage()
                  })
                  .catch(err => {
                    console.log(err.response.data.message)
                    const stock = this.orderData.reduce((p, c) => {
                      p[c.productId] = parseInt(c.amount)
                      return p
                    }, {})
                    const quantity = this.selectList.some(item => {
                      return item.amount < stock[item.productId]
                    })
                    if (quantity) {
                      this.$message.error(`${err.response.data.message}`, 3)
                    }else{
                      this.$message.error('編輯出貨失敗')
                    }
                  })
              }
            }
          }
        })
      })
    },
    deleteOrder(row, index) {
      if (this.changeTitle === '新增出貨') {
        this.orderData.splice(index,1)
      } else {
        if (row.id) {
          // this.$api.Distribute.deleteCommodityDiscount(row)
          this.orderData.splice(index, 1)
        } else {
          this.orderData.splice(index, 1)
        }
      }
    },
    getCustomerList() {
      this.isGettingCustomer = true
      this.$api.Inventory.onlyCustomerList().then(res => {
        this.customerList = res.data
        this.isGettingCustomer = false
      })
    },
    onPressEnterStockRemark(ev) {
      if (ev.shiftKey) return this.isShiftEnterStockRemark = true

      ev.preventDefault()
    },
    getClass() {
      this.$api.Customer.getClass().then(res => {
        this.classesList = res.data.map(item => {
          return item
        })
      })
    },
    CommodityDetail() {
      this.$api.Commodity.getCommodityDetail({
        searchKey: '',
        barcode: this.searchBarcode
      }).then(res => {
        this.inventoryList = res.data
      })
    },
    resetPage() {
      this.distributeList()
    },
    changeDate(expression) {
      switch (expression) {
        case 0:
          this.differentDate = [moment().startOf('day'), moment().endOf('day')]
          this.startDate = this.differentDate[0].format('YYYY-MM-DD')
          this.endDate = this.differentDate[1].format('YYYY-MM-DD')
          this.distributeList()
          break
        case 1:
          this.differentDate = [
            moment()
              .isoWeekday(1)
              .startOf('day'),
            moment()
              .isoWeekday(7)
              .startOf('day')
          ]
          this.startDate = this.differentDate[0].format('YYYY-MM-DD')
          this.endDate = this.differentDate[1].format('YYYY-MM-DD')
          this.distributeList()
          break
        case 2:
          this.differentDate = [
            moment()
              .date(1)
              .startOf('day'),
            moment().endOf('month')
          ]
          this.startDate = this.differentDate[0].format('YYYY-MM-DD')
          this.endDate = this.differentDate[1].format('YYYY-MM-DD')
          this.distributeList()
          break
        case 3:
          this.differentDate = [
            moment()
              .month(moment().month() - 1)
              .date(1)
              .startOf('day'),
            moment()
              .month(moment().month() - 1)
              .endOf('month')
          ]
          this.startDate = this.differentDate[0].format('YYYY-MM-DD')
          this.endDate = this.differentDate[1].format('YYYY-MM-DD')
          this.distributeList()
          break
        case 4:
          this.differentDate = ['']
          this.startDate = this.differentDate[0]
          this.endDate = this.differentDate[0]
          this.distributeList()
          break
      }
    },
    onChange(date, dateString) {
      ;(this.startDate = dateString[0]), (this.endDate = dateString[1])
      this.distributeList()
    },
    distributeList() {
      this.$api.Distribute.getDistributeList({
        orderNo: this.search,
        startDate:
          this.startDate == '' ? this.startDate : this.startDate + ' 00:00:00',
        endDate: this.endDate == '' ? this.endDate : this.endDate + ' 23:59:59',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        this.total = res.data.totalElements
        this.tableData = res.data.content
      })
    },
    exportOrderList() {
      this.$api.Distribute.exportOrderList({
        orderNo: this.search,
        startDate:
                this.startDate == '' ? this.startDate : this.startDate + ' 00:00:00',
        endDate: this.endDate == '' ? this.endDate : this.endDate + ' 23:59:59',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        console.log(res);
        let link = document.createElement("a");
        let url = window.URL.createObjectURL(new Blob([res.data],{ type: "application/octetstream" }));
        link.style.display = "none";
        link.href = url;
        link.setAttribute(
                "download",
                "出貨列表_" + moment(new Date()).format('YYYY-MM-DD') + ".xlsx"
        );
        link.click();
      })
    },
    onShowSizeChange(current, pageSize) {
      this.pageNumber = 1
      this.pageSize = pageSize
      this.distributeList(this.search)
    },
    onPageChange() {
      this.distributeList(this.search)
    },
    passShipDataByApiResponseData(resData = {}) {
      const {tracks} = resData
      const newFreights = []
      tracks.forEach(e => {
        newFreights.push({
          _id: e.id,
          isChicken: e.chicken,
          isEgg: e.egg,
          isVegetable: e.vegetable,
          volume: volumeValueEnum[e.volume],
          temperatureCategory: temperatureValueEnum[e.temperature],
          trackingNo: e.trackingNo,
          shippingFee: e.shippingFee,
        })
      })
      this.freights = newFreights
    },
    showDetail(record) {
      this.resetForm()
      this.currentPage = 1
      this.orderId = record.orderId
      this.orderViewVisible = true
      this.orderModalTitle = '訂單詳情'
      if (this.orderColumns.some(item => item.title === '操作')) {
        this.orderColumns.pop()
      }

      this.$api.Distribute.getDistributeDetail({
        orderId: record.orderId
      }).then(async res => {
        this.createReceiverList(record)
        this.list.receiverList = this.findDefaultInfo

        let nonDefaultList = this.list.receiverList.filter(item => {
          return item.id === this.findDefaultInfo[0].id
        })
        this.defaultList = this.findDefaultInfo.filter(
          (item, i) => i === this.list.defaultReceiveInfo
        )
        if (nonDefaultList[0].id === this.defaultList[0].id) {
          if (this.list.defaultReceiveInfo === 0) {
            this.defaultList[0].default = '同客戶資料(預設地址)'
          } else if (this.list.defaultReceiveInfo === 1) {
            this.defaultList[0].default = '同公司資料(預設地址)'
          } else {
            this.defaultList[0].default = '(預設地址)'
          }
          this.defaultOption = this.defaultList[0].default
          this.recipientId = res.data.recipientId
          this.receiverList = { ...this.defaultList[0] }
        } else {
          this.recipientId = res.data.recipientId
          this.receiverList = { ...nonDefaultList[0] }
        }
        this.orderDetail = res.data
        this.allowance = this.orderDetail.allowance

        let barcodes = []
        res.data.orderDetailItemResponseList.map(item=>{
          barcodes.push(item.barcode)
        })
        await this.$api.Commodity.getProductSalesByCode({
          clientId: res.data.clientId,
          barcodes: barcodes
        }).then(res => {
          this.selectList = [].concat.apply([], res.data)
        })
        this.payments = res.data.payment
        this.orderData = res.data.orderDetailItemResponseList.map(item=>{

          // let price = this.selectList.find(x=>x.barcode == item.barcode).price
          // item.price = price

          let amount = item.amount
          let price
          let selectData = this.selectList.find(x => x.barcode == item.barcode && x.depotId == item.depotId)
          if(selectData){
            price = selectData.price
          }else {
            price = item.price
          }

          return {
            id: item.id,
            productId: item.productId,
            alias: item.alias,
            amount: amount,
            barCode: item.barcode,
            price: price,
            productName:item.productName,
            depotName: item.depotName,
            remark: item.remark,
            clientPrice: item.clientPrice,
            unit: item.unit,
            discount: item.discount,
            weight: item.weight,
            depotId: item.depotId,
            productCategories: item.productCategories
          }
        })
        this.shipment = res.data.shipment
        this.ruleList.customerClass = res.data.classId
        this.ruleList.optionId = res.data.clientId
        this.cusList = this.customerList.filter(item => {
          return item.classes.id === res.data.classId
        })
        this.list = this.customerList.find(item => {
          return item.id === this.ruleList.optionId
        })
        this.getClientFreights(res.data.clientId, false)
        this.passShipDataByApiResponseData(res.data)
        this.trackingNo = res.data.trackingNo
        this.piecesAmount = res.data.piecesAmount > 0 ? res.data.piecesAmount : 0
      })
    },
    copyOrderNo(value,id) {
      let TextRange = document.createRange();
      TextRange.selectNode(document.getElementById(id));
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(TextRange);
      document.execCommand("copy");
      sel.removeAllRanges();
      this.$message.success(`已複製 單號:${value} 至剪貼簿!` )
    },
    cancelHandler(record) {
      this.$api.Distribute.deleteOrderList(record).then(() => {
        record.remark = '註銷'
        this.$message.success(`${record.orderNo}訂單取消成功`)
        this.resetPage()
      })
    },
    searchHandler() {
      this.pageNumber = 1
      this.distributeList()
    },
    distributeChange({ current, pageSize }) {
      this.currentPage = current
      this.pageSizes = pageSize
    },
    resetForm() {
      this.$refs.ruleForm?.resetFields()
    },
    createReceiverList(record) {
      this.list = this.customerList.find(item => {
        return item.id === record.clientId
      })
      this.findDefaultInfo = [
        { id: '', receiver: '', address: '', tel: '', postCode: '' },
        { id: '', receiver: '', address: '', tel: '', postCode: '' }
      ]
      this.findDefaultInfo.push(...this.list.recipientList)
      this.findDefaultInfo[0].id = '0'
      this.findDefaultInfo[0].receiver = this.list.name
      this.findDefaultInfo[0].address = this.list.address
      this.findDefaultInfo[0].postCode = this.list.postCode
      this.findDefaultInfo[0].tel = this.list.tel
      this.findDefaultInfo[1].id = '1'
      this.findDefaultInfo[1].receiver = this.list.companyName
      this.findDefaultInfo[1].address = this.list.companyAddress
      this.findDefaultInfo[1].postCode = this.list.companyPostCode
      this.findDefaultInfo[1].tel = this.list.companyTel
    },
    amountChecking(row) {
      const stock = this.orderData.reduce((p, c) => {
        p[c.productId] = parseInt(c.amount)
        return p
      }, {})
      const quantity = this.selectList.some(item => {
        return (
          item.amount < stock[item.productId] && item.barcode === row.barCode && item.depotId === row.depotId
        )
      })
      const name = this.orderData.findIndex(item => {
        return item.barCode === row.barCode
      })
      if (quantity) {
        this.$message.error(
          `${this.orderData[name].productName}\n出貨量大於庫存量`,
          3
        )
      }
    }
  },
  watch: {
    orderViewVisible(visible){
      const modalKeyupBind = (listenerKey) => {
        const modal = document.querySelector('#modal-wrapper')
        if (modal) {
          modal[listenerKey]('keyup', this.handleOk, false)
        }
      }
      if (visible) {
        setTimeout(() => modalKeyupBind('addEventListener'), 400)
      } else {
        modalKeyupBind('removeEventListener')
      }
    }
  },
  computed: {
    shipmentMsgEnum() { return shipmentMsgEnum },
    shipmentValueEnum() { return shipmentValueEnum },
    shipmentValueToKeyEnum() { return shipmentValueToKeyEnum },
    temperatureMsgEnum() { return temperatureMsgEnum },
    temperatureValueEnum() { return temperatureValueEnum },
    temperatureValueToKeyEnum() { return temperatureValueToKeyEnum },
    volumeMsgEnum() { return volumeMsgEnum },
    volumeValueEnum() { return volumeValueEnum },
    volumeValueToKeyEnum() { return volumeValueToKeyEnum },
    countShippingFee() {
      let amount = 0
      const { freights } = this
      freights?.forEach(e => {
        amount += Number(e.shippingFee) || 0
      })
      return amount
    },
    totalOrder() {
      const { orderData } = this
      let count = new Decimal(0), amount = new Decimal(0)
      orderData?.forEach(e => {
        const isWeight = checkWeightUnit(e.unit)
        const _count = isWeight ? new Decimal(e.weight || 0) : new Decimal(e.amount || 0);
        count = count.plus(_count);
        amount = amount.plus(
          new Decimal(e.clientPrice || 0).times(_count).minus(new Decimal(e.discount || 0)).toDecimalPlaces(2)
        );
      })
      return { count: count.toNumber(), amount: amount.toNumber() };
    },
    totalAmount() {
      const { countShippingFee, totalOrder, allowance } = this
      return countShippingFee + totalOrder.amount - (Number(allowance) || 0)
    },
    Quantity() {
      return (val, row, key) => {
        let editKey =
          'isEdit' + key[0].toUpperCase() + key.substring(1, key.length)
        return {
          children: (
            <div class="displayInput">
              {row[editKey] ? (
                <div style="display: flex;">
                  {key === 'clientPrice' || key === 'discount' ?
                    <span style="margin: auto;">$</span>
                    :''
                  }
                  <a-input
                    class="bbb"
                    placeholder="請輸入"
                    value={row[key]}
                    vModel={row[key]}
                    onKeyup={() => {
                      if(key === 'discount' || key === 'clientPrice'){
                        let value = row[key]
                        value = value.replace(/[^\d.]/g, ""); //清除"數字"和"."以外的字元
                        value = value.replace(/^\./g, ""); //驗證第一個字元是數字
                        value = value.replace(/\.{2,}/g, "."); //只保留第一個, 清除多餘的
                        value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                        value = value.replace(/^(\-)*(\d+)\.(\d)(\d).*$/, '$2.$3$4'); //只能輸入兩個小數
                        row[key] = value
                      } else if(key === 'amount'){
                        row[key] = row[key].replace(/[^\d]/g, '')
                      }
                    }
                    }
                    // onKeyup={() =>
                    //   (key === 'discount' ||
                    //           key === 'amount' || key === 'clientPrice') &&
                    //   (row[key] = row[key].replace(/[^\d]/g, ''))
                    // }
                    onChange={() =>
                      key === 'amount' ? this.amountChecking(row) : ''
                    }
                    vOn:Keyup_enter_stop={() => this.addNewItem(row, editKey)}
                  />
                </div>
              ) : this.orderModalTitle !== '訂單詳情' ? (
                <Fragment>
                  <span onClick={() => this.inputORnot(row, editKey)}>
                    {key === 'discount'
                      ? '$' + formatPrice(val)
                      : val}
                  </span>
                  <div class="displayEdit" />
                  <a-icon
                    class="editable-cell-icon"
                    type="edit"
                    onClick={() => this.inputORnot(row, editKey)}
                  />
                </Fragment>
              ) : (
                <span>
                  {key === 'discount' || key === 'clientPrice'
                    ? '$' + val?.toString()
                    .replace(/^(\d+\.+\d{1,2})\d*$/, '$1')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : val}
                </span>
              )}
            </div>
          )
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .ant-modal-content {
  margin: -50px -101px 0 -101px;
}
.container {
  position: relative;
}
.distribute-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.distribute-action .calendar {
  margin-right: 10px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.ant-calendar-picker {
  /*padding: 5px;*/
}
.detail-menu {
  display: flex;
  flex-direction: column;
}
.addButton2 {
  background-color: #fba129;
  color: #fcfcfc;
  font-weight: bold;
  font-size: large;
  border: unset;
  margin-right: auto;
}
.addPurchaseView::v-deep .ant-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  font-size: 16px;
}
.addPurchaseView div * {
  margin-right: 10px;
}
.firstPart {
  background-color: #f5f5f5;
  padding: 15px 20px 15px 20px;
}
.firstPart-item {
  display: flex;
}
.firstPart-item > div {
  flex: 1;
}
.sales-detail {
  margin-top: 15px;
  padding: 15px 20px;
  background-color: #f5f5f5;
  &::v-deep {
    .ant-form-item-label {
      width: 80.8px;
    }
  }
}

.second-part {
  background-color: #f5f5f5;
  margin: 15px 0px 0 0px;
  padding: 0px 20px 0px 20px;
  .editable-add-btn {
    margin-top: 10px;
    /*margin-bottom: 15px;*/
    .discount {
      display: flex;
      align-items: center;
    }
    .discount > button {
      margin-left: 5px;
      float: left;
    }
    .discount-menu {
      margin-top: 5px;
      flex-direction: column;
    }
  }
}
.option-content {
  display: flex;
  margin: 0 8px 8px 0;
}
.print-wrapper {
  height: 100px;
  margin-top: 10px;
  background-color: #f5f5f5;
  .button-wrapper {
    .print-btn {
      background-color: #fba129;
      color: #fcfcfc;
      font-size: large;
      border: unset;
      cursor: pointer;
    }
  }
}
.saleTable {
  background-color: #fff;
}
.displayInput {
  display: flex;
  justify-content: space-evenly;
}
.displayEdit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.editable-cell-icon {
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  top: 3.5px;
}
.dark-row {
  background-color: red;
}
.t-cat-freight {
  border: 1px solid rgba(#000, .1);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.custom-form-item, .option-content {
  &::v-deep {
    .ant-form-item-label {
      text-align: left;
      > label:not(.ant-form-item-required) {
        padding-left: 11px;
      }
    }
  }
}
</style>
